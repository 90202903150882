import { gql } from '@apollo/client'

export const GET_GENERA = gql`
  query Genera($filter: GenusFilter, $limit: Int, $offset: Int) {
    genera(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        name
        code
      }
      total
    }
  }
`
