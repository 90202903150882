import { gql } from '@apollo/client'

export const GET_INSP_LOCATIONS = gql`
  query inspectLocations($filter: FarmFilter) {
    farms(filter: $filter) {
      id: internal
      name
    }
  }
`

export const GET_INSP_GENERA = gql`
  query inspectGenera {
    crops {
      id: internal
      name
    }
  }
`

export const GET_INSP_JOB_TYPES = gql`
  query inspectJobTypes($filter: JobTypeFilter) {
    jobTypes(filter: $filter) {
      id: internal
      name
    }
  }
`

export const TENANT_SETTINGS = gql`
  query tenantSettings {
    inspectTenant {
      settings {
        compressionSettings {
          defaultRate
          parallelProcessNumber
          overwriteOption
          thumbnailSmartResize
          thumbnailFixedSize {
            width
            height
          }
        }
      }
    }
  }
`

export const UPDATE_TENANT_SETTINGS = gql`
  mutation updateInspectTenantSettings($id: ID!, $input: InspectTenantSettingsInput!) {
    updateInspectTenantSettings(id: $id, input: $input) {
      settings {
        compressionSettings {
          defaultRate
          parallelProcessNumber
          overwriteOption
          thumbnailSmartResize
          thumbnailFixedSize {
            width
            height
          }
        }
      }
    }
  }
`