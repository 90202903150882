import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMField } from '@mprise/react-ui'
import { FieldSelect } from './field-select'
import { MultiSelectDialog } from '../dialog/multi-select-dialog'
import { GET_INSP_LOCATIONS } from '../../gql/inspect'

export type SimpleInspectLocation = {
  id: number
  name: string
}

export const FieldInspectLocationSet = () => {
  const f = useMField()
  const { t } = useTranslation()
  const selected = f.value as SimpleInspectLocation[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const onSave = (selectedItems: SimpleInspectLocation[]) => {
    f.onChange?.(selectedItems)
    handleClose()
  }

  return (
    <>
      {open && (
        <MultiSelectDialog<SimpleInspectLocation>
          displayProperty='name'
          open={open}
          onClose={handleClose}
          onSave={onSave}
          title={t('Inspect Locations')}
          initialValue={selected}
          getRecordsQuery={GET_INSP_LOCATIONS}
          queryResultAccessor={data => data?.farms}
          additionalVariables={{active: true}}
          applyFrontendSorting={true}
          useDefaultQueryFilters={false}
        />
      )}
      <FieldSelect<SimpleInspectLocation>
        displayProperty='name'
        selected={selected}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
