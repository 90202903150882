import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMField } from '@mprise/react-ui'
import { FieldSelect } from './field-select'
import { MultiSelectDialog } from '../dialog/multi-select-dialog'
import { GET_ATTRIBUTES } from '../../gql/attributes'

export type SimpleAttribute = {
  id: number
  code: string
  name: string
}

export const FieldAttributeSet = ({
  disabled,
  filterByPageType,
}: {
  disabled?: boolean
  filterByPageType?: string
}) => {
  const f = useMField()
  const { t } = useTranslation()
  const selected = f.value as SimpleAttribute[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const onSave = (selectedItems: SimpleAttribute[]) => {
    f.onChange?.(selectedItems)
    handleClose()
  }

  useEffect(() => {
    if (disabled) {
      handleClear()
    }
  }, [disabled])

  return (
    <>
      {open && (
        <MultiSelectDialog<SimpleAttribute>
          displayProperty='code'
          open={open}
          onClose={handleClose}
          onSave={onSave}
          title={t('Attributes')}
          initialValue={selected}
          getRecordsQuery={GET_ATTRIBUTES}
          queryResultAccessor={data => data?.workAttributes?.page}
          applyFrontendSorting={true}
          additionalVariables={filterByPageType ? { pageType: filterByPageType } : undefined}
        />
      )}
      <FieldSelect<SimpleAttribute>
        disabled={disabled}
        displayProperty='code'
        selected={selected}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
