import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { MFieldConnector, Yup } from '@mprise/react-ui'
import { Box } from '@mui/system'
import { Field, useFormikContext } from 'formik'
import { MutationResult, useLazyQuery, useMutation } from '@apollo/client'
import { useHistory } from '../../shared/use-history'
import { PatternDialog } from '../../shared/pattern-dialog'
import { Maybe } from '../../shared/typescript'
import { withFormikCompareFix } from '../../shared/formik'
import { useLocalState } from '../../shared/react-local-state'
import { DeleteRecordDialog } from '../../shared/dialog/delete-record-dialog'
import {
  DELETE_ADDITIONAL_ATTRIBUTE_RULE,
  GET_ADDITIONAL_ATTRIBUTE_RULE,
  GET_ADDITIONAL_ATTRIBUTE_RULES,
  UPSERT_ADDITIONAL_ATTRIBUTE_RULE,
} from '../../gql/additional-attribute-rules'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { FieldAttributeSet } from '../../shared/form/field-attribute-set'
import { FieldDropdown } from '../../shared/form/field-dropdown'
import { MutationErrorMessage } from '../../shared/react-apollo'
import { FieldActivitySet } from '../../shared/form/field-activity-set'
import { FieldGenusSet } from '../../shared/form/field-genus-set'

export interface UpsertAttributeRuleFormValues {
  id?: Maybe<number>
  pageType: string
  activities: Array<{ id: number }>
  genera: Array<{ id: number }>
  attributes: Array<{ id: number }>
}

export const AdditionalAttributeRulesDialog = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = AdditionalAttributeRulesDialog.useSchema()
  const companyId = useCurrentCompanyId()

  const { ruleId } = useParams() as { ruleId: string }

  const [getAdditionalAttributeRule, { data: ruleData, loading: ruleLoading }] =
    useLazyQuery(GET_ADDITIONAL_ATTRIBUTE_RULE)

  const [upsertAdditionalAttributeRule, { error: upsertError }] = useMutation(UPSERT_ADDITIONAL_ATTRIBUTE_RULE, {
    refetchQueries: [GET_ADDITIONAL_ATTRIBUTE_RULES],
  })

  useEffect(() => {
    if (ruleId) {
      getAdditionalAttributeRule({
        variables: {
          companyId: +companyId,
          id: +ruleId,
        },
      })
    }
  }, [companyId, getAdditionalAttributeRule, ruleId])

  const [initialValues] = useLocalState<UpsertAttributeRuleFormValues>(() => {
    const rule = ruleData?.getAdditionalAttributeRule
    return withFormikCompareFix({
      id: rule?.id ?? null,
      pageType: rule?.pageType ?? null,
      activities: rule?.activities ?? [],
      genera: rule?.genera ?? [],
      attributes: rule?.attributes ?? [],
    })
  }, [ruleData])

  const handleSave = async (formValues: UpsertAttributeRuleFormValues) => {
    const response = await upsertAdditionalAttributeRule({
      variables: {
        companyId: +companyId,
        input: {
          id: formValues.id,
          pageType: formValues.pageType,
          activities: formValues.activities.map(x => +x.id),
          genera: formValues.genera.map(x => +x.id),
          attributes: formValues.attributes.map(x => +x.id),
        },
      },
    })
    const upsertedRuleId = response.data?.upsertAdditionalAttributeRule?.id
    if (upsertedRuleId) {
      handleClose()
    }
  }

  const handleClose = () => {
    h.push('/additional-attribute-rules', { replace: true })
  }

  const [deleting, setDeleting] = useState<Maybe<{ id: number; name: string }>>(null)
  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    const rule = ruleData?.getAdditionalAttributeRule
    if (rule) {
      setDeleting(rule)
    }
  }
  const deleteDialogClose = () => setDeleting(null)

  return (
    <>
      {deleting && (
        <DeleteRecordDialog
          record={deleting}
          recordType={t('Additional Attribute Rule')}
          onClose={deleteDialogClose}
          editPopupClose={handleClose}
          deleteQuery={DELETE_ADDITIONAL_ATTRIBUTE_RULE}
          variables={{ companyId: +companyId, id: deleting.id }}
          refetchQueries={[GET_ADDITIONAL_ATTRIBUTE_RULES]}
        />
      )}
      <PatternDialog<UpsertAttributeRuleFormValues>
        title={ruleId ? t('Edit Additional Attribute Rule') : t('New Additional Attribute Rule')}
        submit={ruleId ? t('Save') : t('Create')}
        initialValues={initialValues}
        onClose={handleClose}
        onSave={handleSave}
        onDelete={ruleId ? handleDelete : undefined}
        schema={schema}
        open={true}
        query={[{ loading: ruleId ? ruleLoading : false }]}
      >
        <MutationErrorMessage mutation={{ error: upsertError } as MutationResult} />
        <AddionalAttributeRulesDialogForm />
      </PatternDialog>
    </>
  )
}

const AddionalAttributeRulesDialogForm = () => {
  const { t } = useTranslation()
  const fc = useFormikContext<UpsertAttributeRuleFormValues>()
  useEffect(() => {
    if (fc.dirty) {
      fc.setFieldValue('attributes', [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: Should not include 'fc', that causes an infinite render loop.
  }, [fc.values.pageType])
  return (
    <Box minWidth={400} padding={2}>
      <Field component={MFieldConnector} name='pageType' label={t('Page')}>
        <FieldDropdown values={['JOB', 'WORK_TASK']} />
      </Field>
      <Field component={MFieldConnector} name='activities' label={t('Activities')}>
        <FieldActivitySet disabled={!(fc.values.pageType === 'WORK_TASK')} />
      </Field>
      <Field component={MFieldConnector} name='genera' label={t('Genera')}>
        <FieldGenusSet />
      </Field>
      <Field component={MFieldConnector} name='attributes' label={t('Attributes')}>
        <FieldAttributeSet disabled={!!(fc.values.pageType === null)} filterByPageType={fc.values.pageType} />
      </Field>
    </Box>
  )
}

AdditionalAttributeRulesDialog.useSchema = () => {
  const { t } = useTranslation()
  return Yup.object().shape({
    pageType: Yup.string().label(t('Page')).required(t('Page type is required')),
    activities: Yup.array().label(t('Activities')),
    genera: Yup.array().label(t('Genera')),
    attributes: Yup.array().label(t('Attributes')).min(1, t('At least one Attribute is required')),
  })
}
