import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { PostAdd } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, MText, MTextColor } from '@mprise/react-ui'
import { useLazyQuery } from '@apollo/client'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { SimpleDataGrid } from '../../shared/simple-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { GridColDef } from '@mui/x-data-grid'
import { useHistory } from '../../shared/use-history'
import { QueryErrorMessage } from '../../shared/react-apollo'
import { GET_ADDITIONAL_ATTRIBUTE_RULES } from '../../gql/additional-attribute-rules'

const columns: GridColDef[] = [
  { field: 'pageType', headerName: 'Page', width: 250, valueGetter: params => params.row.pageType },
  {
    field: 'activities',
    headerName: 'Activities',
    width: 250,
    valueGetter: params => params.row.activities?.map((activity: any) => activity.code).join(', ') || '-',
  },
  {
    field: 'genera',
    headerName: 'Genera',
    width: 250,
    valueGetter: params => params.row.genera?.map((x: any) => x.code).join(', ') || '-',
  },
  {
    field: 'attributes',
    headerName: 'Attributes',
    width: 250,
    valueGetter: params => params.row.attributes?.map((x: any) => x.code).join(', '),
  },
]

export const AdditionalAttributeRulesPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet title={t('Additional Attributes')} />

      <MPageTitle label={t('Additional Attributes')} />

      <MText block textVariant='small' textColor={MTextColor.shadow} style={{ paddingLeft: '1.5rem' }}>
        {t('ADDITIONAL_ATTRIBUTES_EXPLANATION')}
      </MText>

      <AdditionalAttributeRulesTable />
    </>
  )
}

const AdditionalAttributeRulesTable = () => {
  const { t } = useTranslation()
  const h = useHistory()
  const companyId = useCurrentCompanyId()

  const [getAdditionalAttributeRules, { data, loading, error, refetch }] = useLazyQuery(
    GET_ADDITIONAL_ATTRIBUTE_RULES,
    {
      notifyOnNetworkStatusChange: true,
      onError: console.error,
    },
  )

  useEffect(() => {
    getAdditionalAttributeRules({
      variables: {
        companyId: +companyId,
      },
    })
  }, [companyId, getAdditionalAttributeRules])

  const handleEditRule = (rule: any, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    h.push(`/additional-attribute-rules/edit/${rule.id}`)
  }

  return (
    <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
      <MFlex grow={1} alignItems='center'>
        <Box marginX={1} marginY={2}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<PostAdd />}
            children={t('Add New Rule')}
            component={Link}
            to='/additional-attribute-rules/new'
          />
        </Box>
        <MFlexItem minWidth='auto' grow={10} shrink={2} />
      </MFlex>

      <QueryErrorMessage query={{ loading, error, refetch }} />

      <SimpleDataGrid
        columns={columns}
        data={data?.getAdditionalAttributeRules}
        onRowClick={handleEditRule}
        rowCount={data?.getAdditionalAttributeRules?.length ?? 0}
        loading={loading}
      />
    </MFlexBlock>
  )
}
