import { gql } from '@apollo/client'

export const GET_ADDITIONAL_ATTRIBUTE_RULE = gql`
  query GetAdditionalAttributeRule($companyId: Int!, $id: Int!) {
    getAdditionalAttributeRule(companyId: $companyId, id: $id) {
      id
      pageType
      activities {
        id
        code
        name
      }
      genera {
        id
        code
        name
      }
      attributes {
        id
        code
        name
      }
    }
  }
`

export const GET_ADDITIONAL_ATTRIBUTE_RULES = gql`
  query GetAdditionalAttributeRules($companyId: Int!) {
    getAdditionalAttributeRules(companyId: $companyId) {
      id
      pageType
      activities {
        id
        code
        name
      }
      genera {
        id
        code
        name
      }
      attributes {
        id
        code
        name
      }
    }
  }
`

export const UPSERT_ADDITIONAL_ATTRIBUTE_RULE = gql`
  mutation UpsertAdditionalAttributeRule($companyId: Int!, $input: UpsertAdditionalAttributeRuleInput!) {
    upsertAdditionalAttributeRule(companyId: $companyId, input: $input) {
      id
    }
  }
`

export const DELETE_ADDITIONAL_ATTRIBUTE_RULE = gql`
  mutation DeleteAdditionalAttributeRule($companyId: Int!, $id: Int!) {
    deleteAdditionalAttributeRule(companyId: $companyId, id: $id)
  }
`
