import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMField } from '@mprise/react-ui'
import { FieldSelect } from './field-select'
import { SingleSelectDialog } from '../dialog/single-select-dialog'
import { GET_GENERA } from '../../gql/genera'

export type SimpleGenus = {
  id: number
  name: string
  code: string
}

export const FieldGenus = () => {
  const f = useMField()
  const { t } = useTranslation()
  const selected = f.value as SimpleGenus
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.(null)

  const onSave = (selectedItems: SimpleGenus) => {
    f.onChange?.(selectedItems)
    handleClose()
  }

  return (
    <>
      {open && (
        <SingleSelectDialog<SimpleGenus>
          displayProperty='name'
          open={open}
          onClose={handleClose}
          onSave={onSave}
          title={t('Genera')}
          initialValue={selected}
          getRecordsQuery={GET_GENERA}
          queryResultAccessor={data => data?.genera?.page}
        />
      )}
      <FieldSelect<SimpleGenus>
        displayProperty='name'
        selected={selected}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
