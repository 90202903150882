import { gql } from '@apollo/client'

export const GET_ATTRIBUTES = gql`
  query WorkAttributes($filter: WorkAttributeFilter, $limit: Int, $offset: Int) {
    workAttributes(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
      }
      total
    }
  }
`
