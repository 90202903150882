import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMField } from '@mprise/react-ui'
import { FieldSelect } from './field-select'
import { MultiSelectDialog } from '../dialog/multi-select-dialog'
import { GET_GENERA } from '../../gql/genera'
import type { SimpleGenus } from './field-genus'

export const FieldGenusSet = () => {
  const f = useMField()
  const { t } = useTranslation()
  const selected = f.value as SimpleGenus[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const onSave = (selectedItems: SimpleGenus[]) => {
    f.onChange?.(selectedItems)
    handleClose()
  }

  return (
    <>
      {open && (
        <MultiSelectDialog<SimpleGenus>
          displayProperty='code'
          open={open}
          onClose={handleClose}
          onSave={onSave}
          title={t('Genera')}
          initialValue={selected}
          getRecordsQuery={GET_GENERA}
          queryResultAccessor={data => data?.genera?.page}
          applyFrontendSorting={true}
        />
      )}
      <FieldSelect<SimpleGenus>
        displayProperty='code'
        selected={selected}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
